import { Injectable, OnDestroy } from '@angular/core';
import { SocketWrapperService, SocketMessage } from './socket-wrapper.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Subscription, Subject } from 'rxjs';
import { CredentialsService } from 'src/app/modules/auth/credentials.service';
import { NotificationsService } from '../notifications.service';
import { Notification } from '../../models/Notification.models';
import { PaginatedApiResponse } from '../../models/api-response/paginated-api-response';



@Injectable({
  providedIn: 'root'
})
export class RealtimeNotificationsService implements OnDestroy {
  private sub$: Subscription;
  public notificationHistory: PaginatedApiResponse<Notification>;
  public showNotificationsManager = false;
  public page: string = '1';
  public pageSize: string = '20';
  // unread
  public count: number = 0;
  constructor(
    private notificationsService: NotificationsService,
    private credentialsService: CredentialsService,
    private socketWrapperService: SocketWrapperService,
    private nzNotificationService: NzNotificationService
  ) { }


  ngOnDestroy() {
    if (this.sub$) this.sub$.unsubscribe();
  }

  public run() {
    this.credentialsService.userObservable.subscribe(_ => this.init());
  }
  private init() {
    this.loadNotificationForPage(this.page);
    this.socketWrapperService.onNewSubject.subscribe((sm: Subject<SocketMessage>) => {
      if (!sm) {
        console.log('waiting in rns...');
        return;
      }
      console.log('reconnect on rns')
      if (this.sub$) this.sub$.unsubscribe();
      this.sub$ = sm.subscribe((m: SocketMessage) => {
        this.processMessage(m);
      });
    });
  }

  /*
   * Load notificationHistory
   */
  public loadNotificationForPage(pi: number | string): void {
    if (!this.credentialsService.currentUser) {
      return;
    }
    this.page = String(pi);
    let filters = [];
    filters.push({ param: 'recipient_id', value: this.credentialsService.currentUser.id });
    filters.push({ param: 'page', value: this.page });
    filters.push({ param: 'page_size', value: this.pageSize });

    this.notificationsService.getByFilters(filters)
      .subscribe(r => {
        // remove old and update notifications history
        this.notificationHistory = r;
        this.updateCount();
      });
  }

  /*
   * Clear notifications history (locally);
   */
  public clear(): void {
    this.notificationHistory = null;
    this.updateCount();
  }

  private updateCount() {
    if (this.notificationHistory && this.notificationHistory.results) {
      this.count = this.notificationHistory.results.filter(v => !v.notified).length;
    } else {
      this.count = 0;
    }
  }


  /*
   * Process socket message
   * (handles only specific types of SocketMessages)
   */
  private processMessage(m: SocketMessage) {
    if (m.type == "notification") {
      const payload: Notification = m.payload as Notification;
      this.processNotification(payload);
    }
    else if (m.type == 'joined_room') {
      const room: number = +m.payload["room_name"];

      // TODO remember
      // this.filterNotificationHistory((n: Notification) => n.conversation_id != room);
    }
  }

  /*
   * Processes a notification
   */
  private processNotification(notification: Notification) {
    // if (notification.conversation_id) {
    //   this.chatService.setConversationLastMessage(notification.conversation_id, notification.text);
    // }
    if (
      notification.recipient_id &&
      (!this.credentialsService.currentUser ||
        this.credentialsService.currentUser.id != notification.recipient_id)) {
      // current user is not a recipient, exclude this notification
      return;
    }

    this.notify(notification);
    // this.markNotificationAsRead(notification);
  }

  /*
   * Notifies user (UI)
   */
  private notify(notification: Notification) {

    // if (notification.conversation_id !== null) {
    //   if (this.chatService.room && notification.conversation_id === +this.chatService.room) {
    //     this.markNotificationAsRead(notification);
    //     return;
    //   }
    // }


    // TODO: redirect_path
    this.nzNotificationService.create(
      notification.type,
      notification.title,
      notification.text,
      {}
    );
    // this.notificationHistory = { ...this.notificationHistory, results: [...this.notificationHistory.results, notification] };
    this.notificationHistory.results = [notification, ...this.notificationHistory.results];
    this.updateCount();
  }

  // private filterNotificationHistory(filter: (Notification) => boolean) {
  //   this.notificationHistory = this.notificationHistory.filter(v => filter(v));
  // }

  /*
   * Mark notificaton as read (on server)
   */
  public markNotificationAsRead(notification: Notification) {
    if (notification.id) {
      this.socketWrapperService.messages.next({
        type: "notification_ack",
        payload: {
          notification_id: notification.id
        }
      });
      notification.notified = true;
    }
  }

  // TODO: optimize
  markAllAsRead() {
    this.notificationHistory.results.forEach(n => {
      if (!n.notified) this.markNotificationAsRead(n);
    });
    this.updateCount();
  }


  removeNotification(notification: Notification) {
    this.notificationsService.delete(notification.id)
      .subscribe(v => {
        this.notificationHistory = { ...this.notificationHistory, results: this.notificationHistory.results.filter(v => v.id != notification.id) };
        this.updateCount();
      });
  }
}
