import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import {
  NZ_I18N, en_US, NzConfig, NZ_CONFIG, NzBadgeModule,
  NzLayoutModule, NzButtonModule, NzMenuModule, NzDropDownModule,
  NzModalModule, NzMessageModule, NzNotificationModule, NzIconModule,
  NzGridModule
} from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { CoreModule } from './core/core.module';
import { httpInterceptorProviders } from './core/interceptors';
import { PendingChangesGuard } from './core/services/guards/pending-changes.guard';

registerLocaleData(en);



const ngZorroConfig: NzConfig = {
  message: {
    nzDuration: 3000,
    nzMaxStack: 10,
    nzPauseOnHover: true,
    nzAnimate: true
  },
  notification: {
    nzTop: 74,
    nzBottom: 74,
    nzPlacement: 'topRight',
    nzDuration: 4500,
    nzMaxStack: 1,
    nzPauseOnHover: true,
    nzAnimate: true
  }
};


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    AppRoutingModule,
    IconsProviderModule,
    NzGridModule,
    NzLayoutModule,
    NzButtonModule,
    NzBadgeModule,
    NzDropDownModule,
    NzMenuModule,
    NzModalModule,
    NzMessageModule,
    NzNotificationModule,
    NzIconModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: 'req',
      useValue: null
    },
    httpInterceptorProviders,
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    PendingChangesGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
