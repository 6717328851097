import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalButtonsService {
  private showButtons$ = new BehaviorSubject<boolean>(false);
  private onBuildButtonPressed$ = new BehaviorSubject<boolean>(false);
  private onShieldsButtonPressed$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  // set showButtons value
  public set showButtons(value) {
    this.showButtons$.next(value);
  }

  //
  // observables

  public getShowButtonsObservable() {
    return this.showButtons$.asObservable().pipe(share());
  }

  public getOnBuildButtonPressedObservable() {
    return this.onBuildButtonPressed$.asObservable().pipe(share());
  }

  public getOnShieldsButtonPressedObservable() {
    return this.onShieldsButtonPressed$.asObservable().pipe(share());
  }

  //
  // emitters

  public emitOnBuildButtonPressed() {
    this.onBuildButtonPressed$.next(true);
  }

  public emitOnShieldsButtonPressed() {
    this.onShieldsButtonPressed$.next(true);
  }
}
