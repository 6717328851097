import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SeoService } from './services/seo.service';
import { NzResultModule } from 'ng-zorro-antd';
import { GlobalButtonsService } from './services/global-buttons.service';
import { SocketService } from './services/socket/socket.service';
import { SocketWrapperService } from './services/socket/socket-wrapper.service';
import { NotificationsService } from './services/notifications.service';
import { RealtimeNotificationsService } from './services/socket/realtime-notifications.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzResultModule
  ],
  declarations: [
    PageNotFoundComponent
  ],
  exports: [
    PageNotFoundComponent
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        SeoService,
        GlobalButtonsService,
        SocketService,
        SocketWrapperService,
        NotificationsService,
        RealtimeNotificationsService
      ]
    }
  }
}
