import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { Subject, Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import { SocketService } from './socket.service';
import { map, tap, share } from 'rxjs/operators';

export class SocketMessage {
  type: string;
  payload: any;
}

@Injectable({
  providedIn: 'root'
})
export class SocketWrapperService implements OnDestroy {
  public messages: Subject<SocketMessage>;
  public room: string;

  private subject$: BehaviorSubject<Subject<SocketMessage>> = new BehaviorSubject(null);

  private reconnectSubscription: Subscription;

  constructor(private wsService: SocketService) {
    this.connect();
  }

  ngOnDestroy() {
    if (this.messages) this.messages.complete();
    if (this.reconnectSubscription) this.reconnectSubscription.unsubscribe();
    if (this.subject$) this.subject$.complete();
  }

  public get onNewSubject() {
    return this.subject$.asObservable().pipe(share());
  }

  private connect(): void {
    if (this.reconnectSubscription) return;
    this.reconnectSubscription = this.wsService.onReconnectObservable().subscribe((s: Subject<MessageEvent>) => {
      if (s === null) {
        // console.log('waiting in project socket service...');
        return null;
      }
      // console.log('reconnected in project socket service');
      this.messages = <Subject<SocketMessage>>s.pipe(
        map((response: MessageEvent): SocketMessage => {
          let data = JSON.parse(response.data);
          return data;
        }),
        share()
      );

      this.subject$.next(this.messages);
      return this.messages;
    });
    this.wsService.connect()
  }

  public joinRoom(room: string) {
    this.room = room;
    if (this.messages) {
      this.messages.next({
        type: "join_room_request",
        payload: {
          room_name: room
        }
      });
    } else {
      console.warn("Cannot connect to room, Subject is null")
    }
  }

  public emit(type: string, payload: object) {
    if (this.messages) {
      this.messages.next({
        type: type,
        payload: payload
      });
    } else {
      console.warn("Cannot emit msg, Subject is null")
    }
  }


  public leaveRoom() {
    if (this.messages && this.room) {
      this.messages.next({
        type: "leave_room",
        payload: null
      });
      this.room = null;
    }
  }


  public disconnect() {
    if (this.messages) this.messages.complete();
  }

}
