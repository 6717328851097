import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../models/User.model';
import { environment } from 'src/environments/environment';
import { catchError, first } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { CustomEncoder } from './custom.encoder';
import { ErrorHandlerService } from './error-handler.service';
import { PaginatedApiResponse } from '../models/api-response/paginated-api-response';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = `${environment.API_URL}/auth/users`;
  private basePublicUrl = `${environment.API_URL}/users`;

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService) {
  }

  public getUserById(id: number | string): Observable<User> {
    return this.http.get<User>(`${this.basePublicUrl}/${id}/`).pipe(first());
  }

  public getUsers(page: string, page_size: string, query: string = null): Observable<PaginatedApiResponse<User>> {
    const options =
      { params: new HttpParams({ encoder: new CustomEncoder() }).set('page', page).set('page_size', page_size) };

    if (query) {
      options.params = options.params.set('search', query);
    }

    return this.http.get<PaginatedApiResponse<User>>(`${this.basePublicUrl}/`, options).pipe(first());
  }

  public getByClassroomId(classroomId: number): Observable<PaginatedApiResponse<User>> {
    const filters = [{ param: 'classrooms__id', value: String(classroomId) }];
    return this.getByFilters(filters);
  }

  public getNext(next: string): Observable<PaginatedApiResponse<User>> {
    return this.http.get<PaginatedApiResponse<User>>(next).pipe(first());
  }

  public getCurrentUser(): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/me/`)
      .pipe(
        first(),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
  }


  public patchCurrentUser(patch: object): Observable<User> {
    return this.http.patch<User>(`${this.baseUrl}/me/`, patch)
      .pipe(
        first(),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
  }

  public patchUser(id, patch: object): Observable<User> {
    return this.http.patch<User>(`${this.basePublicUrl}/${id}/`, patch)
      .pipe(
        first(),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          return of(null);
        })
      )
  }


  public getByFilters(filters: Array<{ param: string, value: string }> = []): Observable<PaginatedApiResponse<User>> {
    let params = new HttpParams({ encoder: new CustomEncoder() });

    for (let item of filters) {
      params = params.append(item.param, item.value);
    }

    const options =
      { params: params };

    return this.http.get<PaginatedApiResponse<User>>(`${this.basePublicUrl}/`, options)
      .pipe(
        first(),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          return throwError(error);
        })
      );
  }


}
