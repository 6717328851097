import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { TokenInfo } from './models';
import { ReplaySubject, Observable } from 'rxjs';
import { User } from 'src/app/core/models/User.model';
import { share } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'src/app/core/services/cookie.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  // current user
  private user$: ReplaySubject<User> = new ReplaySubject<User>(1);
  public currentUser: User;

  private _tokenInfo: TokenInfo;
  public tokenInfo$: ReplaySubject<TokenInfo> = new ReplaySubject<TokenInfo>(1);

  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.init();
  }

  private init() {
    this.getTokenInfoFromCookies();
  }

  public getTokenInfo() {
    if (!this._tokenInfo) {
      this.getTokenInfoFromCookies();
    }
    return this._tokenInfo;
  }

  public processNewUser(user: User) {
    this.currentUser = user;
    this.user$.next(user);
  }
  public get userObservable(): Observable<User> {
    return this.user$.asObservable().pipe(share());
  }

  // get/set
  public get isLoggedIn(): boolean {
    return (this._tokenInfo && this._tokenInfo.auth_token != null);
  }

  public get authorizationToken(): string {
    return this._tokenInfo ? `Token ${this._tokenInfo.auth_token}` : null;
  }



  //
  // cookies management
  //
  private storeTokenInfo(token: TokenInfo) {
    this.cookieService.setWithExpiryInYears(environment.COOKIE_TOKEN_KEY, JSON.stringify(token), 1);
    this._tokenInfo = token;

    this.tokenInfo$.next(this._tokenInfo);
  }

  public getTokenInfoFromCookies(): TokenInfo {
    const cookie = this.cookieService.get(environment.COOKIE_TOKEN_KEY);
    if (!cookie) {
      return new TokenInfo();
    }

    const token = JSON.parse(cookie) as TokenInfo;
    if (!this._tokenInfo || !token || this._tokenInfo.auth_token != token.auth_token) {
      this._tokenInfo = token;
      this.tokenInfo$.next(this._tokenInfo);
    } else {
      this._tokenInfo = token;
    }

    console.log(token);
    return token;
  }

  private removeTokenInfo() {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.removeItem(environment.COOKIE_TOKEN_KEY);
      this._tokenInfo = null;
      this.tokenInfo$.next(null);
    }
  }



  public processUserLogout() {
    this.removeTokenInfo();
  }

  public processSucceedLogin(tokenInfo: TokenInfo): void {
    this.storeTokenInfo(tokenInfo);
  }

}
