import { Component, OnInit, ViewChild, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { AuthService } from './modules/auth/auth.service';
import { RealtimeNotificationsService } from './core/services/socket/realtime-notifications.service';
import { CookieService } from './core/services/cookie.service';
import { isPlatformBrowser } from '@angular/common';
import { GlobalButtonsService } from './core/services/global-buttons.service';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  public showGlobalButtons = false;
  private showGlobalButtonsSub: Subscription;
  @ViewChild('notificationTemplate', { static: true }) notificationTemplate;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public rns: RealtimeNotificationsService,
    public cookiesService: CookieService,
    public authService: AuthService,
    public globalButtonsService: GlobalButtonsService
  ) { }

  ngOnInit(): void {
    if (this.authService.credentialsService.isLoggedIn) {
      this.rns.run();
    }

    this.showGlobalButtonsSub = this.globalButtonsService
      .getShowButtonsObservable().pipe(delay(0)).subscribe(v => this.showGlobalButtons = v);
  }

  ngOnDestroy() {
    if (this.showGlobalButtonsSub) this.showGlobalButtonsSub.unsubscribe();
  }

  logout() {
    // unsubscription is handled by AuthService
    this.authService.logout().subscribe(v => {
      if (isPlatformBrowser(this.platformId)) window.location.reload();
    });
  }

  cookiesAgree() {
    this.cookiesService.agree();
  }
}
