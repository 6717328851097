import { Injectable, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { Observable, of, throwError, ReplaySubject, Subscription } from 'rxjs';
import { tap, mapTo, catchError, first, takeWhile } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { UploadXHRArgs } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { isPlatformBrowser } from '@angular/common';
import { LoginApiRequest } from './models/login-api-request.model';
import { RegisterApiRequest } from './models/register-api-request.model';
import { LogoutApiRequest } from './models/logout-api-request.model';
import { User } from 'src/app/core/models/User.model';
import { UserService } from 'src/app/core/services/user.service';
import { CredentialsService } from './credentials.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  public loading = false;

  public redirectUrl: string;

  private tokenInfoSubscription: Subscription;
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.tokenInfoSubscription) this.tokenInfoSubscription.unsubscribe();
  }
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    public credentialsService: CredentialsService) {
    // unsubscribtion is handled by OnDestoy method
    this.tokenInfoSubscription = this.credentialsService.tokenInfo$.subscribe(tokenInfo => {
      if (tokenInfo === null) {
        this.processNewUser(null);
      } else {
        this.reloadCurrentUser();
      }
    });
  }



  public reloadCurrentUser(): void {
    if (!this.credentialsService.isLoggedIn) {
      console.warn('Trying to get current user while client is not logged in.');
      return;
    }

    // unsubscription is handled by UserService
    this.userService.getCurrentUser()
      .subscribe(user => {
        if (user) {
          this.processNewUser(user as User);
        }
      });
  }


  public processNewUser(user: User) {
    this.credentialsService.processNewUser(user);
    if (user) {
      this.applyRedirectUrl();
    }
  }

  public patchCurrentUser(p: any): Observable<User> {
    if (!this.credentialsService.currentUser) {
      return of(null);
    }
    return this.http.patch<User>(this.credentialsService.currentUser.url, p)
      .pipe(
        first(),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          throw error;
        })
      );
  }


  /*
   * TODO: subscription management
   * You should unsubscribe from this subscription
   */
  public updateCurrentUserAvatar(item: UploadXHRArgs): Observable<HttpEvent<{}>> {
    if (!this.credentialsService.currentUser) {
      item.onError!(new Error('No user for avatar uploading'), item.file!);
      return throwError(new Error('No user to upload avatar'));
    }
    const formData = new FormData();

    formData.append('image', item.file as any); // item.file type is UploadFile
    // formData.append('id', '1000');

    const uploadUrl: string = `${environment.API_URL}/auth/users/${this.credentialsService.currentUser.id}/`;

    const req = new HttpRequest('PATCH', uploadUrl, formData, {
      reportProgress: true,
      withCredentials: true
    });

    return this.http.request(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.processNewUser(event.body as User);
        }
      })
    );
  }


  // Redirect Url
  public applyRedirectUrl() {
    // Redirect the user
    if (this.redirectUrl) {
      let redirect = this.router.parseUrl(this.redirectUrl);
      this.redirectUrl = null;
      this.router.navigateByUrl(redirect);
    }
  }


  // Auth methods
  public login(credentials: LoginApiRequest): Observable<boolean> {
    this.loading = true;
    return this.http.post<{ auth_token: string }>(`${environment.API_URL}/auth/token/login/`, credentials)
      .pipe(
        first(),
        tap((v: { auth_token: string }) => {
          this.credentialsService.processSucceedLogin(v);
          this.loading = false;
        }),
        mapTo(true),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          this.loading = false;
          return throwError(error);
        })
      );
  }

  public register(credentials: RegisterApiRequest): Observable<boolean | HttpErrorResponse> {
    this.loading = true;
    return this.http.post<User>(`${environment.API_URL}/auth/users/`, credentials)
      .pipe(
        first(),
        tap((user: User) => {
          this.loading = false;
        }),
        mapTo(true),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          this.loading = false;

          return throwError(error);
        })
      );
  }

  public logout(): Observable<boolean> {
    return this.http.post<LogoutApiRequest>(`${environment.API_URL}/auth/token/logout/`, {})
      .pipe(
        first(),
        tap((_any: any) => this.credentialsService.processUserLogout()),
        mapTo(true),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          return throwError(error);
        })
      );
  }

  public activate(uid: string, token: string): Observable<boolean> {
    return this.http.post<any>(`${environment.API_URL}/auth/users/activation/`, {uid, token})
      .pipe(
        first(),
        tap((_any: any) => console.log(_any)),
        mapTo(true),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          return throwError(error);
        })
      );
  }

  public resetPasswordRequest(email: string) {
    return this.http.post<any>(`${environment.API_URL}/auth/users/reset_password/`, {email})
      .pipe(
        first(),
        mapTo(true),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          return throwError(error);
        })
      );
  }

  public confirmPasswordReset(uid: string, token: string, new_password: string, re_new_password: string): Observable<boolean> {
    return this.http.post<any>(`${environment.API_URL}/auth/users/reset_password_confirm/`, {uid, token, new_password, re_new_password})
      .pipe(
        first(),
        tap((_any: any) => console.log(_any)),
        mapTo(true),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          return throwError(error);
        })
      );
  }


  /*
   *
   * Social authentication
   *
   */
  public loginUsingGoogle() {
    return this.startSocialAuthProcess('google-oauth2');
  }

  public loginUsingFacebook() {
    return this.startSocialAuthProcess('facebook');
  }

  /*
   * Accepts code from provider and tries to log in a user using a given code
   */
  public getTokenForSucceededSocialAuth(provider: string, code: string) {
    this.loading = true;
    const url = `${environment.API_URL}/auth/social/token/${provider}/`;

    return this.http.post<{ token: string, refresh: string, user: string }>(url, { code: code })
      .pipe(
        first(),
        tap(r => {
          this.credentialsService.processSucceedLogin({ auth_token: r.token });
          this.loading = false;
        }),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          this.loading = false;
          return throwError(error);
        })
      );
  }

  // Generates authorization url from server and redirects user.
  private startSocialAuthProcess(provider: 'google-oauth2' | 'facebook') {
    this.loading = true;
    return this.http.get<{ authorization_url: string }>(`${environment.API_URL}/auth/o/${provider}/?redirect_uri=${environment.SOCIAL_AUTH_REDIRECT_URI}`)
      .pipe(
        first(),
        tap((r: { authorization_url: string }) => {
          // redirect user to authorization url
          if (isPlatformBrowser(this.platformId)) {
            window.location.href = r.authorization_url;
          }

          // this.loading = false;
        }),
        mapTo(true),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          this.loading = false;
          return throwError(error);
        })
      );
  }

}
