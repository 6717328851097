import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, CanLoad, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CredentialsService } from '../modules/auth/credentials.service';

@Injectable({
  providedIn: 'root'
})
export class WelcomeRedirectGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private credentialsService: CredentialsService,
    private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    let url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    let url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean> {
    if (this.credentialsService.isLoggedIn) {
      this.router.navigate(['/', 'projects', 'my']);
    } else {
      this.router.navigate(['/', 'projects', 'details', 'block-demo']);
    }

    return of(false);
  }

}
