import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PaginatedApiResponse } from '../models/api-response/paginated-api-response';
import { CustomEncoder } from './custom.encoder';
import { ErrorHandlerService } from './error-handler.service';
import { Notification } from '../models/Notification.models';
import { first, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private baseUrl = `${environment.API_URL}/notifications`
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService) {
  }

  public getNotificationById(id: number): Observable<Notification> {
    return this.http.get<Notification>(`${this.baseUrl}/${id}/`).pipe(first());
  }

  public getByFilters(filters: Array<{ param: string, value: string }> = []) {
    let params = new HttpParams({ encoder: new CustomEncoder() });

    for (let item of filters) {
      params = params.append(item.param, item.value);
    }

    const options =
      { params: params };

    return this.http.get<PaginatedApiResponse<Notification>>(`${this.baseUrl}/`, options)
      .pipe(
        first(),
        catchError(error => {
          this.errorHandlerService.handleError(error);
          return throwError(error);
        })
      );
  }

  public delete(id: string | number): Observable<null> {
    return this.http.delete<null>(`${this.baseUrl}/${id}/`).pipe(first());
  }


  public getNotifications(page: string, pageSize: string, query: string = null): Observable<PaginatedApiResponse<Notification>> {
    let params = new HttpParams({ encoder: new CustomEncoder() }).set('page', page).set('page_size', pageSize);

    if (query) {
      params = params.set('search', query);
    }

    const options =
      { params: params };

    return this.http.get<PaginatedApiResponse<Notification>>(`${this.baseUrl}/`, options).pipe(first());
  }

  public getNextNotifications(next: string): Observable<PaginatedApiResponse<Notification>> {
    return this.http.get<PaginatedApiResponse<Notification>>(next).pipe(first());
  }

}
